<template>
<div>
    <v-container class="fill-height pa-0 pt-1 px-md-3 mb-6" fluid>
        <v-flex lg9>
            <MainCarousel />
        </v-flex>
        <v-flex v-if="$vuetify.breakpoint.lgAndUp" lg3>
            <MainPageTopBanners />
        </v-flex>
    </v-container>
    <InfoBlock class="mb-6" type="text" />
    <InfoBlock class="mb-6" type="block-main" />
    <!-- <v-alert text prominent type="info" elevation="3" icon="mdi-package-check" class="mb-6 mx-3 text-center text-weight-bold">
        <div class="d-flex justify-space-between align-start px-0 px-md-12 text-left second--text">
            <div>
                <p class="link--text font-weight-bold">Шановні партнери!</p>
                <p>В зв’язку із проведенням на складі Амтел <span class="link--text font-weight-bold">річної повної інвентаризації в період 04.04 - 06.04</span>, просимо врахувати графік по прийому замовлень та відвантажень:
                    </p>
                <p class="link--text font-weight-bold">Доставка транспортом ЮК:</p>
                <v-simple-table dense light class="mb-4">
                    <tr>
                        <td width="10%" class="pr-2">02.04.24</td>
                        <td width="10%" class="d-none d-sm-flex">(ВТ)</td>
                        <td>Прийом замовлень та відвантаження відповідно до діючого графіка.</td>
                    </tr>
                    <tr>
                        <td width="10%" class="pr-2" :colspan="$vuetify.breakpoint.smAndUp ? `2` : ``">03.04.24 - 06.04.24</td>
                        <td>Приймаємо замовлення. <span class="link--text font-weight-bold">Відвантаження не здійснюються</span>. На складі інвентаризація.</td>
                    </tr>
                    <tr>
                        <td width="10%" class="pr-2">08.04.24</td>
                        <td width="10%" class="d-none d-sm-flex">(ПН)</td>
                        <td>Початок відвантаження замовлень. Звичайний графік роботи.</td>
                    </tr>
                </v-simple-table>
                <p class="link--text font-weight-bold">Доставка НП, або самовивіз з Амтел:</p>
                <v-simple-table dense light class="mb-4">
                    <tr>
                        <td width="10%" class="pr-2">02.04.24</td>
                        <td width="10%" class="d-none d-sm-flex">(ВТ)</td>
                        <td>Прийом замовлень та відвантаження відповідно до діючого графіка.</td>
                    </tr>
                    <tr>
                        <td width="10%" class="pr-2">03.04.24</td>
                        <td width="10%" class="d-none d-sm-flex">(СР)</td>
                        <td>Приймаємо замовлення. <span class="link--text font-weight-bold">Самовивіз</span> - до 13-00; <span class="link--text font-weight-bold">НП</span> -  до 12-00.</td>
                    </tr>
                    <tr>
                        <td width="10%" class="pr-2" :colspan="$vuetify.breakpoint.smAndUp ? `2` : ``">04.04.24 - 06.04.24</td>
                        <td>Приймаємо замовлення. <span class="link--text font-weight-bold">Відвантаження не здійснюються</span> на складі інвентаризація.</td>
                    </tr>
                    <tr>
                        <td width="10%" class="pr-2">08.04.24</td>
                        <td width="10%" class="d-none d-sm-flex">(ПН)</td>
                        <td>Початок відвантаження замовлень. Звичайний графік роботи.</td>
                    </tr>
                </v-simple-table>
                <p>Звертайтеся до ваших менеджерів та робіть замовлення - разом підтримаємо економіку України поки наші військові битимуть російського окупанта!</p>
                <p class="font-weight-bold">Бажаємо гарних свят та швидкої перемоги!</p>
            </div>
        </div>
    </v-alert> -->
    <Slider  v-if="contactInfo.groups && this.contactInfo.groups.cl_corp_action && sliderHotItems.length" :items="sliderHotItems" title="Гарячі пропозиції" :loading="loadingHot" />
    <ActionsSlider v-if="contactInfo.groups && this.contactInfo.groups.cl_corp_action && actionsItems.length" class="mb-6" :items="actionsItems" title="Акції" :loading="loadingActions" />
    <Slider v-if="contactInfo.groups && this.contactInfo.groups.cl_corp_bonus && bonusesItems.length" :items="bonusesItems" title="Бонусні програми" bonus :loading="loadingBonuses" />
    <Slider v-if="showSlider['sliderNewItems']" :items="sliderNewItems" title="Нові надходження" :loading="loadingNew" />
    <Slider v-if="showSlider['visitedItems']" :items="visitedItems" hide-price title="Ви переглядали" :loading="loadingVisited" />

    <Intersector title="Рекомендуємо" />
    <InfoBlockDialog />

</div>
</template>

<script>
import MainCarousel from './MainCarousel.vue'
import Slider from './Slider.vue'
import MainPageTopBanners from './MainPageTopBanners.vue'
import Intersector from './Intersector.vue'
import ActionsSlider from './ActionsSlider.vue'
import InfoBlockDialog from './InfoBlockDialog.vue'
import InfoBlock from './InfoBlock.vue'

import {
    mapActions
} from 'vuex'

export default {
    components: {
        MainCarousel,
        MainPageTopBanners,
        Slider,
        Intersector,
        ActionsSlider,
        InfoBlockDialog,
        InfoBlock,
    },
    data() {
        return {
            loadingHot: false,
            loadingNew: false,
            loadingVisited: false,
            loadingActions: false,
            loadingBonuses: false,
        }
    },
    methods: {
        ...mapActions(['Reconciliation', 'getBalanceInformer', 'getInfoBlocks', 'getHotSliderItems', 'getNewSliderItems', 'getVisitedItems', 'getActionsSliderItems', 'catalogVisited', 'getBonusesItems', 'touch']),
    },
    mounted() {
        if (this.$vuetify.breakpoint.lgAndUp) {
            this.$store.commit('openCloseNavigationDrawer', true)
        }
        this.catalogVisited(this.$route.path)
    },
    created() {
        this.touch()
            .then(() => {
                //this.loadingHot = true
                // this.loadingNew = true
                // this.loadingVisited = true
                // this.loadingActions = true
                // this.loadingBonuses = true

                this.getHotSliderItems()
                    .then(() => this.loadingHot = false)

                this.getNewSliderItems()
                    .then(() => this.loadingNew = false)

                this.getVisitedItems()
                    .then(() => this.loadingVisited = false)

                this.getActionsSliderItems()
                    .then(() => this.loadingActions = false)
                this.getBonusesItems()
                    .then(() => this.loadingBonuses = false)

                //this.getInfoBlocks()
            })
            .catch(error => console.error(error))

    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        catalogTree() {
            return this.$store.state.main.catalogTree
        },
        sliderHotItems() {
            return this.$store.state.catalog.sliderHotItems
        },
        sliderNewItems() {
            return this.$store.state.catalog.sliderNewItems
        },
        visitedItems() {
            return this.$store.state.catalog.visitedItems
        },
        actionsItems() {
            return this.$store.state.catalog.actionsItems
        },
        bonusesItems() {
            return this.$store.state.catalog.bonusesItems
        },
        showSlider() {
            const slider = {
                sliderNewItems: (this.$vuetify.breakpoint.smAndUp ? false : true),
                visitedItems: (this.$vuetify.breakpoint.smAndUp ? false : true),
            }
            if (this.sliderNewItems && this.sliderNewItems.length > 4) {
                slider.sliderNewItems = true
            }
            if (this.visitedItems && this.visitedItems.length > 4) {
                slider.visitedItems = true
            }

            return slider
        },
    },
}
</script>

<style>
a {
    text-decoration: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0
}
</style>
