<template>
<v-dialog v-model="open" persistent width="600">
    <v-card v-if="!userInfo().is_wholesale_onbehalf_ok">
        <v-system-bar color="white" light>
            <v-spacer></v-spacer>
            <v-icon @click="setOpenRatingDialog(false)">mdi-close</v-icon>
        </v-system-bar>
        <v-card-title v-if="!todayRated">
            Дякуємо!
        </v-card-title>
        <v-card-title v-if="!todayRated">
            <div>Вашa оцінкa: {{rating}}</div>
        </v-card-title>
        <v-card-title v-if="todayRated">
            Дякуємо. Ваша оцінка вже була прийнята сьогодні.
        </v-card-title>
        <v-card-title v-if="todayRated">
            <div>Ваша оцінка: {{currRating}}</div>
        </v-card-title>
        <v-card-text v-if="todayRated">
            <p>Можливість змінити свою оцінку у вас з'явиться завтра.</p>
        </v-card-text>

        <v-card-text v-if="rating < 5 && !todayRated">
            <p class="subtitle-1">Прокоментуйте, будь ласка, що вам завадило поставили оцінку 5.</p>
        </v-card-text>
        <v-card-text v-if="rating < 5 && !todayRated">
            <v-textarea outlined v-model="comment" label="Ваш коментар"></v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn v-if="comment != '' && !todayRated" color="primary" text @click="go()">
                Надiслати
            </v-btn>
            <v-btn v-if="$route.name != 'empty'" color="" text @click="setOpenRatingDialog(false)">
                Відмінити
            </v-btn>
        </v-card-actions>
    </v-card>

    <v-card v-if="userInfo().is_wholesale_onbehalf_ok">
        <v-system-bar color="white" light>
            <v-spacer></v-spacer>
            <v-icon @click="setOpenRatingDialog(false)">mdi-close</v-icon>
        </v-system-bar>
        <v-card-title v-if="currRating">
            <div>Клієнт оцінив співробітництво з компанією:</div>
        </v-card-title>
        <v-card-title v-if="currRating">
            <div>Oцінка: {{currRating}}</div>
        </v-card-title>
        <v-card-title v-if="!currRating">
            <div class="mx-auto">Клієнт не оцінював співробітництво з компанією</div>
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="$route.name != 'empty'" color="" text @click="setOpenRatingDialog(false)">
                Закрити
            </v-btn>
        </v-card-actions>
    </v-card>

</v-dialog>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    props: {
        rating: Number,
        //ratingDate: Date
    },
    data(){
        return {
            comment: '',
            isLoading: false,
        }
    },
    methods: {
        ...mapGetters(['userInfo']),
        ...mapActions(['sendRating', 'getRating','setSnackbar']),
        setOpenRatingDialog(v) {
            if (!this.todayRated && !this.userInfo().is_wholesale_onbehalf_ok && this.rating == 5){
                this.go()
            }
            this.$store.commit('setRatingDialog', v)
        },
        go() {
            this.sendRating({
                    rating: this.rating,
                    comment: this.comment
            })
            this.setSnackbar(['info', 'Дякуємо. Ваша оцінка прийнята'])
            this.$store.commit('setRatingDialog', false)
        }
    },
    watch: {
    },
    mounted() {
    },
    computed: {
        open: {
            get: function () {
                return this.$store.state.main.ratingDialog
            },
            // setter
            set: function (v) {
                this.$store.commit('setRatingDialog', v)
            }
        },
        todayRated() {
            return this.$store.state.main.todayRated
        },
        currRating() {
            return this.$store.state.main.currRating
        },
        ratingDate(){
            return this.$store.state.main.ratingDate
        }
    },
}
</script>
