<template>
<v-dialog v-model="onbehalf" persistent max-width="600">
    <v-card>
        <v-system-bar color="primary" dark>
            <v-spacer></v-spacer>
            <v-icon v-if="$route.name != 'empty'" @click="setOnbehalfDialog(false)">mdi-close</v-icon>
        </v-system-bar>
        <v-card-title>Вхiд від імені Клієнта</v-card-title>
        <v-card-text>
            <div>Вкажіть Клієнта, від імені якого Ви будете знаходитися на В2В-порталі</div>
            <v-autocomplete clearable autofocus cache-items v-model="onbehalfF.client" label="Клієнт" @change="getContacts(onbehalfF.client)" hide-no-data persistent-hint :items="itemsClients" :loading="isLoading" :search-input.sync="searchClients"></v-autocomplete>
            <v-select clearable prepend-icon="mdi-account" v-model="onbehalfF.contact" persistent-hint hide-no-data :items="itemsContacts" :loading="isLoadingContacts" label="Контакт Клієнта"></v-select>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="$route.name != 'empty'" color="" text @click="setOnbehalfDialog(false)">
                Скасувати
            </v-btn>
            <!-- {{onbehalfF}} -->
            <v-spacer></v-spacer>
            <v-btn :disabled="onbehalfF.contact ? false : true" color="primary" text @click="goOnbehalf()">
                Увійти
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    data: () => ({
        onbehalfF: {},
        itemsClients: [],
        itemsContacts: [],
        searchClients: null,
        isLoading: false,
        isLoadingContacts: false,
    }),
    methods: {
        ...mapActions(['getClientsAutocomplete', 'getContactsAutocomplete', 'setOnbehalf']),
        queryClientsSelections(v) {
            this.isLoading = true
            this.itemsContacts = []
            this.getClientsAutocomplete(v)
                .then((data) => {
                    this.itemsClients = data.filter(e => {
                        return (e.text || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
                    })
                    this.isLoading = false
                })
                .catch(err => console.log(err))
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        getContacts(v) {
            this.onbehalfF = {}
            this.isLoadingContacts = true
            this.getContactsAutocomplete(v)
                .then((data) => {
                    this.itemsContacts = data
                    this.isLoadingContacts = false
                })
                .catch(err => console.log(err))
        },
        setOnbehalfDialog(v) {
            this.$store.commit('setOnbehalfDialog', v)
        },
        goOnbehalf() {
            this.setOnbehalf(this.onbehalfF)
                .then(() => {
                    this.setOnbehalfDialog(false)
                    this.setLocalStorage('shoppingCart', [])
                    window.location = '/'
                })
                .catch(error => console.log(error))
        }
    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        onbehalf() {
            return this.$store.state.main.onbehalfDialog
        }
    },
    watch: {
        searchClients(val) {
            val && val !== this.onbehalfF.client && this.queryClientsSelections(val)
        },
    },
}
</script>
