<template>
<div >
    <!-- <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" dark app fixed short color="grey darken-4" height="70"> -->
    <v-app-bar :hide-on-scroll="$vuetify.breakpoint.smAndDown" :clipped-left="$vuetify.breakpoint.smAndUp ? true : false" dark app fixed short color="grey darken-4" :height="$vuetify.breakpoint.smAndUp ? `70` : `55`">
        <!-- Лого для десктопа  -->
        <v-toolbar-title v-if="!showSearch && !$browserDetect.isEdge && $vuetify.breakpoint.smAndUp">
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <router-link style="cursor:pointer;" to="/">
                        <span v-on="on">
                            <v-img src="/img/logo_sun_n30.png" />
                        </span>
                    </router-link>
                </template>
                <span>На головну</span>
            </v-tooltip>
        </v-toolbar-title>
        <!-- лого для телефона -->
        <v-toolbar-title v-if="!showSearch && !$browserDetect.isEdge && $vuetify.breakpoint.xs">
            <router-link style="cursor:pointer;" to="/">
                <v-img src="/img/logo_sun_n30.png" width="230" />
            </router-link>
        </v-toolbar-title>

        <!-- лого для Edge -->
        <v-img v-if="$browserDetect.isEdge" height="60" position="left center" contain src="/img/logo_sun_n30.png" />

        <!-- лого для телефона при строке поиска -->
        <v-toolbar-title v-if="showSearch">
            <router-link style="cursor:pointer;" to="/">
                <v-img src="/img/logo_sun_n_sm.png" width="48"/>
            </router-link>
        </v-toolbar-title>
        <transition appear>
            <v-text-field light v-if="showSearch" class="pl-3" @change="hideSearch()" @blur="showSearch = false" v-model="searchStr" flat outlined solo dense hide-details prepend-inner-icon="search" @click:append="scanner = !scanner" append-icon="mdi-barcode" label="Шукати товар"></v-text-field>
        </transition>
        <v-spacer></v-spacer>
        <v-text-field light @keyup.enter="searchIn()" class="hidden-sm-and-down" v-model="searchStr" flat outlined solo dense hide-details prepend-inner-icon="search" label="Шукати товар"></v-text-field>

        <v-spacer></v-spacer>

        <v-btn icon class="hidden-md-and-up mt-0" v-if="!showSearch" @click="showSearch = true">
            <v-icon>search</v-icon>
        </v-btn>


        <div :class="loading ? 'shake shake-constant':''">
            <v-btn icon @click="openShoppingCart()" class="mr-n2 mr-sm-0">
                <v-badge color="primary" offset-y="10" left v-if="shoppingCartCounter" :content="shoppingCartCounter">
                    <v-icon>shopping_cart</v-icon>
                </v-badge>
                <v-icon v-if="!shoppingCartCounter">mdi-cart-outline</v-icon>
            </v-btn>
        </div>
        <template v-slot:extension>
            <v-card class="mx-auto" tile width="100%" elevation="0">
                <v-toolbar color="grey darken-1" dark flat height="48px">
                    <v-btn small class="hidden-lg-and-up" dark color="primary" @click.stop="drawerOpenClose()">
                        <v-icon>menu</v-icon>
                        <!-- <v-icon dark right small>{{navigationDrawerOpened ? "arrow_drop_up" : "arrow_drop_down"}}</v-icon> -->
                    </v-btn>
                    <!-- <v-btn class="hidden-sm-and-down" color="primary" @click.stop="drawerOpenClose()">
                        <v-icon left color="white">menu</v-icon>каталог товарів
                        <v-icon dark right v-if="delay">{{navigationDrawerOpened ? "arrow_drop_up" : "arrow_drop_down"}}</v-icon>
                    </v-btn> -->
                    <v-spacer></v-spacer>
                    <span class="hidden-sm-and-down font-weight-thin">Як Ви оцінюєте співпрацю з ЮГ-Контракт? </span>
                    <span @click="openRatingDialog()">
                        <v-rating :readonly="userInfo().is_wholesale_onbehalf_ok || todayRated" v-model="rating" background-color="orange lighten-4" color="orange lighten-2" class="px-2"></v-rating>
                    </span>
                    <!-- @input="openRatingDialog($event)" -->

                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div class="d-flex align-center">
                                <!-- <div class="caption font-weight-thin pr-4" v-if="$vuetify.breakpoint.smAndUp">
                                    <div >Вітаємо,</div>
                                    <div class="font-weight-bold">{{userInfo().on_behalf_of_user_id ? userInfo().name : contactInfo.contactName}}!</div>
                                </div> -->
                                <v-btn icon @click.stop="drawerRightOpenClose()" v-on="on" class="mr-n2 mr-sm-0">
                                    <v-icon>perm_identity</v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <span>Головне меню</span>
                    </v-tooltip>
                </v-toolbar>
            </v-card>
        </template>
    </v-app-bar>
    <template v-if="$vuetify.breakpoint.lgAndUp">
        <NavigationDrawerLeftDesktop />
        <div class="nav-cover"></div>
    </template>
    <NavigationDrawerLeft v-if="!$vuetify.breakpoint.lgAndUp" />
    <NavigationDrawerRight />
    <RatingDialog :rating="rating" />
    <ShoppingCartDialog />

    <v-dialog v-model="scanner" class="ma-1" max-width="300">
        <v-toolbar flat height="48px">
            <v-toolbar-title> Відскануйте товар</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="scanner=false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card class="d-flex justify-center" flat tile>
            <v-card class="pa-2 align-self-center" flat tile>
                <v-card-text>
                    <video id="video" width="200" height="260" style="border: 2px solid green"></video>
                </v-card-text>
            </v-card>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import NavigationDrawerLeftDesktop from '@/components/NavigationDrawerLeftDesktop.vue'
import NavigationDrawerLeft from '@/components/NavigationDrawerLeft.vue'
import NavigationDrawerRight from '@/components/NavigationDrawerRight.vue'
import RatingDialog from '@/components/RatingDialog.vue'
import ShoppingCartDialog from '@/components/ShoppingCartDialog.vue'
import {
    BrowserMultiFormatReader
} from '@zxing/library';

import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    components: {
        NavigationDrawerLeft,
        NavigationDrawerLeftDesktop,
        NavigationDrawerRight,
        RatingDialog,
        ShoppingCartDialog,
    },
    data: () => ({
        delay: false,
        searchStr: '',
        rating: 0,
        scanner: false,
        showSearch: false,
        codeReader: undefined,
        loading: false
    }),
    props: {},
    methods: {
        ...mapGetters(['userInfo']),
        ...mapActions(['logOut', 'touch', 'getRating','getShoppingCartData']),
        hideSearch() {
            this.searchIn()
            this.showSearch = false
        },
        openRatingDialog(rating) {
            this.$store.commit('setRatingDialog', true)
        },
        searchIn() {
            if (this.searchStr) {
                this.$router.push('/search/?query=' + encodeURIComponent(this.searchStr))
            }
        },
        drawerOpenClose() {
            this.$store.commit('openCloseNavigationDrawer', true);
        },
        drawerRightOpenClose() {
            this.$store.commit('openCloseNavigationDrawerRight', true);
        },

        logout: function (params) {
            this.touch()
                .then(() => {
                    this.logOut()
                        .then(response => {
                            this.$router.push({
                                name: 'login'
                            })
                        })
                })
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.error(error)
                return ''
            }
            return value
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        onClickOutside() {},
        startScan() {
            // setTimeout(() => {
            //     this.scanner = false
            //     this.searchStr = '6911400387056' // ТЕСТ
            //     this.searchIn()

            // }, 5000);
            this.codeReader = new BrowserMultiFormatReader()
            this.codeReader
                .listVideoInputDevices()
                .then(videoInputDevices => {
                    videoInputDevices.forEach(device => {
                        const firstDeviceId = videoInputDevices[0].deviceId;
                        this.codeReader
                            .decodeOnceFromVideoDevice(undefined, 'video')
                            .then(result => {
                                this.searchStr = result.text
                                this.searchIn()
                                this.scanner = false
                            })
                            .catch(err => console.error(err));

                    })
                })
                .catch(err => console.error(err));
        },
        resetScan() {
            this.codeReader.reset()
            this.result = ''
        },
        openShoppingCart() {
            this.$store.commit('setShoppingCartDialog', true)
            this.$store.commit('setShoppingCartDialogTab', 0)
        }
    },
    created() {
        
        if (this.$vuetify.breakpoint.smAndDown) {
            if (this.$store.state.main.navigationDrawerOpened) {
                this.$store.commit('openCloseNavigationDrawer', false)
            }
            if (this.$store.state.main.navigationDrawerRightOpened) {
                this.$store.commit('openCloseNavigationDrawerRight', false)
            }
        }

        this.getRating()
            .then(data => {
                if (data && data.mark) {
                    this.rating = data.mark || 0
                    this.ratingDate = new Date(data.date)
                }
            })
            .catch(error => console.error(error))

        setTimeout(() => {
            this.delay = true
        }, 0)

        this.getShoppingCartData()
        .then(data => this.$store.commit('setShoppingCartCounter', data.data.length))
        
        setTimeout(() => {
            this.searchStr = this.$route.query.query
        }, 2000)
    },
    computed: {
        // navigationDrawerOpened() {
        //     return this.$store.state.main.navigationDrawerOpened;
        // },
        // navigationDrawerRightOpened() {
        //     return this.$store.state.main.navigationDrawerRightOpened;
        // },
        todayRated() {
            return this.$store.state.main.todayRated
        },
        shoppingCartCounter() {
            return this.$store.state.main.shoppingCartCounter
        }
    },
    watch: {
        scanner(v) {
            if (v) {
                this.startScan()
            } else {
                this.resetScan()
            }
        },
        '$vuetify.breakpoint.mdAndUp'() {
            this.showSearch = false
        },
        shoppingCartCounter() {
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 500)
        }
    },
}
</script>

<style>
.v-toolbar__extension {
    padding: 0;
}

.v-rating .v-icon {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
}

.v-rating .v-icon:hover {
    cursor: pointer !important;
}

.nav-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, .4);
    opacity: 0;
    visibility: hidden;
    transition: opacity .6s;
}

#navigation-drawer:hover+.nav-cover {
    opacity: 1;
    visibility: visible;
}

#navigation-drawer.hidden+.nav-cover {
    opacity: 0;
    visibility: hidden;
}

.video {
    width: 100%;
    position: relative;
}
</style>
