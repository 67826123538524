<template>
    <MainPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import MainPage from '../components/MainPage.vue'

export default {
    data: () => ({
    }),
    components: {
        MainPage
    },
    methods: {
        ...mapActions(['getUserInfo'])
    },
    mounted() {
        //this.getUserInfo(this)
    }
}
</script>

