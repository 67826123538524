import {
    HTTP
} from '../http-common'


export default {
    state: {
        list: [],
        specList: [],
        total: 0,
        loading: false,
        actionData: {},
        actionFiles: undefined,
        searchGoods: [],
        actionGoods: [],
        totals: undefined,
        filters: {},
        actionsGifts: {}
    },
    mutations: {
        setList(state, payload) {
            state.list = payload
        },
        setSpecList(state, payload) {
            state.specList = payload
        },
        setTotal(state, payload) {
            state.total = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setData(state, payload) {
            state.actionData = payload
        },
        setFiles(state, payload) {
            state.actionFiles = payload
        },
        setGoods(state, payload) {
            state.actionGoods = payload
        },
        setSearchGoods(state, payload) {
            state.searchGoods = payload
        },
        setTotals(state, payload) {
            state.totals = payload
        },
        setFilters(state, payload) {
            state.filters = payload
        },
        setActionsGifts(state, payload) {
            state.actionsGifts = payload
        },
        

    },
    actions: {
        actionsPing({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/actions/ping' )
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        actionsList(
        {
            commit,
        }, {options, stats, cats, ress, types, actionId,goodId}) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    page: options.page,
                    stats,
                    cats,
                    ress,
                    types,
                    actionId,
                    goodId
                }
                HTTP.get('/actions/list', {params} )
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setList', response.data.content.actionsList)
                            commit('setTotal', response.data.content.total)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getProductActionsData(
            {
                commit,
            }, goodId ) {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true)
                    const params = {
                        stats: ['current','work_reg'],
                        goodId
                    }
                    HTTP.get('/catalog/product-actions-list', {params} )
                        .then(response => {
                            commit('setLoading', false)
                            if (response.data && response.data.content) {
                                commit('setList', response.data.content.actionsList)
                                commit('setSpecList', response.data.content.specActionsList)
                                commit('setActionsGifts', response.data.content.actionsGifts)
                                resolve(response.data.content)
                            } else {
                                resolve([])
                            }
                        })
                        .catch(e => {
                            commit('setLoading', false)
                            console.error(e)
                            reject(e)
                        })
                })
        },
        getProductActionsDataSilent(
            {
                commit,
            }, goodId ) {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true)
                    const params = {
                        stats: ['current','work_reg'],
                        goodId
                    }
                    HTTP.get('/catalog/product-actions-list', {params} )
                        .then(response => {
                            commit('setLoading', false)
                            if (response.data && response.data.content) {
                                resolve(response.data.content)
                            } else {
                                resolve([])
                            }
                        })
                        .catch(e => {
                            commit('setLoading', false)
                            console.error(e)
                            reject(e)
                        })
                })
        },
        actionData(
            {
                commit,
            }, id) {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true)
                    const params = {
                        id
                    }
                    HTTP.get('/actions/data', {params} )
                        .then(response => {
                            commit('setLoading', false)
                            commit('setData', {})
                            commit('setFiles', undefined)
                            commit('setGoods', [])
                            commit('setTotals', undefined)
                            
                            if (response.data && response.data.content) {
                                commit('setData', response.data.content.actionData)
                                commit('setFiles', response.data.content.actionFiles)
                                commit('setGoods', response.data.content.actionGoods)
                                commit('setTotals', response.data.content.totals)
                                resolve(response.data.content)
                            } else {
                                resolve([])
                            }
                        })
                        .catch(e => {
                            commit('setLoading', false)
                            console.error(e)
                            reject(e)
                        })
                })
            },
            searchGoods(
                {
                    commit,
                }, query) {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true)
                    const params = {
                        query
                    }
                    HTTP.get('/actions/search-goods', {params} )
                        .then(response => {
                            commit('setLoading', false)
                            if (response.data && response.data.content) {
                                commit('setSearchGoods', response.data.content)
                                resolve(response.data.content)
                            } else {
                                resolve([])
                            }
                        })
                        .catch(e => {
                            commit('setLoading', false)
                            console.error(e)
                            reject(e)
                        })
                })
            },
            getActionsFilters(
                {
                    commit,
                }, {options, stats, cats, ress, types, actionId,goodId}) {
                    return new Promise((resolve, reject) => {
                        commit('setLoading', true)
                        const params = {
                            page: options.page,
                            stats,
                            cats,
                            ress,
                            types,
                            actionId,
                            goodId
                        }
                        HTTP.get('/actions/get-filters', {params} )
                            .then(response => {
                                commit('setLoading', false)
                                if (response.data && response.data.content) {
                                    commit('setFilters', response.data.content)
                                    resolve(response.data.content)
                                } else {
                                    resolve([])
                                }
                            })
                            .catch(e => {
                                commit('setLoading', false)
                                console.error(e)
                                reject(e)
                            })
                    })
            },
        }
}