<template>
<v-card v-if="open && data && !this.userInfo().is_wholesale_onbehalf_ok" outlined :class="type != 'dialog' ? (type == 'text' ? 'ma-1 mt-n4': 'ma-1') : ''">
    <v-system-bar v-if="type != 'text'">
        <v-spacer></v-spacer>
        <v-icon @click="close()">mdi-close</v-icon>
    </v-system-bar>
    <v-card-text>
        <div v-html="data.body" :class="type == 'dialog' ? 'pt-5' : ''"></div>
    </v-card-text>
</v-card>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    data() {
        return {
            typeMapping: {
                'popup': 'dialog',
                'infoblock': 'block-main',
                'infoblock2': 'block',
                'text': 'text',
                'dialog': 'popup',
                'block-main': 'infoblock',
                'block': 'infoblock2',
            }
        }
    },
    props: {
        type: String
    },
    methods: {
        ...mapGetters(['userInfo']),
        ...mapActions(['setInfoBlock']),
        close() {
            this.sendDataToAPI()
        },
        sendDataToAPI() {
            this.setInfoBlock({
                    type: this.typeMapping[this.type],
                    maillist: this.data.maillist_id
                })
                .then(() => {
                    if (this.type == 'dialog') this.$store.commit('setInfoBlockDialog', false)
                    if (this.type == 'block') this.$store.commit('setInfoBlock', false)
                    if (this.type == 'block-main') this.$store.commit('setInfoBlockMain', false)
                })
                .catch(error => console.log(error))

        },
        sendInfo() {
            if (this.type == 'text') this.sendDataToAPI()
        }
    },
    mounted() {
    },
    computed: {
        open() {
            if (this.type == 'dialog') {
                return this.$store.state.main.infoBlockDialog
            }
            if (this.type == 'block') {
                return this.$store.state.main.infoBlock
            }
            if (this.type == 'block-main') {
                return this.$store.state.main.infoBlockMain
            }
            if (this.type == 'text') {
                return this.$store.state.main.infoBlockText
            }
        },
        data() {
            if (this.$store.state.main.infoBlocks)
            return this.$store.state.main.infoBlocks.find(el => el.format == this.typeMapping[this.type])
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },

    },
    watch: {
    },
}
</script>
