import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import main from './store/main'
import catalog from './store/catalog'
import order from './store/order'
import balance from './store/balance'
import actions from './store/actions'
import sales from './store/sales'
import bonus from './store/bonus'
import reserve from './store/reserve'
import payment from './store/payment'
import service from './store/service'
import reports from './store/reports'
import motivation from './store/motivation'


export default new Vuex.Store({
  modules: {
    main,
    catalog,
    order,
    balance,
    actions,
    sales,
    bonus,
    reserve,
    payment,
    service,
    reports,
    motivation,
  }
})
