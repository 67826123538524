<template>
<div class="px-3">
    <div v-show="items.length && !loading" class="pl-4 pr-2 pb-4 primary--text d-flex justify-space-between">
        <div class="title">{{title}}</div>
        <div class="d-flex align-end caption"><router-link to="/actions/">Всі акції<v-icon class="pr-1 mb-1" color="primary">trending_flat</v-icon></router-link></div>
    </div>

    <div class="text-center pa-10" v-show="loading">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
    </div>

    <v-carousel height="218" hide-delimiters v-show="items.length" >
        <v-carousel-item v-for="i in totalSlides" :key="i">
            <ActionsSliderItem :items="items" :i="i" :totalSlides="totalSlides" :sliderCols="sliderCols" />
        </v-carousel-item>
    </v-carousel>
</div>
</template>

<script>
import ActionsSliderItem from './ActionsSliderItem.vue'

export default {
    components: {
        ActionsSliderItem
    },
    props: {
        items: Array,
        title: String,
        loading: Boolean
    },
    data() {
        return {

        }
    },
    methods: {},
    created() {},
    computed: {
        sliderCols() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 1
                case 'sm':
                    return 2
                case 'md':
                    return 3
                case 'lg':
                    return 3
                case 'xl':
                    return 4
            }
        },
        totalSlides() {
            const div = this.items.length % this.sliderCols
            return parseInt(this.items.length / this.sliderCols + (div ? 1 : 0))
        }
    },
}
</script>

<style scoped>
</style>
