import { required, email, max, length } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Це поле обов'язкове"
});

extend("max", {
  ...max,
  message: "Це поле має містити не бiльше {length} символів"
});

extend("length", {
    ...length,
    message: "Це поле має містити {length} символів"
});
  
extend("email", {
  ...email,
  message: "У цьому полі має бути дійсний Email"
});

extend("tel", (value) => {
    if (value.length == 17) {
      return true
    }  
    return 'Заповнiть телефон'
});
  