import Vue from "vue"

const connect = () => {
     return new Promise((resolve, reject) => {
          const socket = new WebSocket((process.env.NODE_ENV === 'production' ? "wss://reports.yugcontract.ua" : "ws://dev.yugcontract.ua:4001"))
          // console.log(socket)
          const se = new Vue({
               methods: {
                    send(message) {
                         if (1 === socket.readyState)
                              socket.send(message)
                    },
                    close() {
                         socket.close()
                    },

               }
          })

          socket.onmessage = function (msg) {
               se.$emit("message", msg.data)
          }

          socket.onclose = function (msg) {
               se.$emit("closed", msg.data)
          }

          socket.onopen = function (msg) {
               se.$emit("opened", msg.data)
               resolve({ se, socket })               
          }
          socket.onerror = function (err) {
               se.$emit("error", err)
               reject(err)
          }
     })
}


export { connect }