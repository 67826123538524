import {
    HTTP,
    HTTP_INTRANET
} from '../http-common'


export default {
    state: {
        list: {},
        total: 0,
        loading: false,
        filters: {},
        contractors: [],
        dayDepts: {},
        paymentUpdated: '',
        contractorDogs: [],
        contractorOverpayment: []
    },
    mutations: {
        setPaymentList(state, payload) {
            state.list = payload
        },
        setTotal(state, payload) {
            state.total = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setFilters(state, payload) {
            state.filters = payload
        },
        setContractors(state, payload) {
            state.contractors = payload
        },
        setPaymentUpdated(state, payload) {
            state.paymentUpdated = payload
        },
        setDayDepts(state, payload) {
            state.dayDepts = payload
        },
        setContractorDogs(state, payload) {
            state.contractorDogs = payload
        },
        setContractorOverpayment(state, payload) {
            state.contractorOverpayment = payload
        },
    },
    actions: {
        getPaymentList({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/payment/get-list', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data)  {
                            commit('setPaymentList', response.data.content.contractorsList)
                            commit('setPaymentUpdated', response.data.content.paymentUpdatedDate)
                            commit('setDayDepts', response.data.content.dayDepts)
                            commit('setContractorDogs', response.data.content.contractorDogs)
                            commit('setContractorOverpayment', response.data.content.contractorOverpayment)
                            resolve(response.data.content.contractorsDept)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getPaymentContractors({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.get('/payment/get-contractors')
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data)  {
                            commit('setContractors', response.data.content.contractors)
                            resolve(response.data.content.contractors)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        reloadPayment({
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP_INTRANET.post('/payment', f)
                    .then(response => {
                        if (response.data)  {
                            resolve(response.data)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getInvoice({
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP.post('/payment/get-invoice', f)
                    .then(response => {
                        if (response.data)  {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
   }
}