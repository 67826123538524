<template>
<div>
    <!-- cycle -->
    <v-carousel cycle :height="$vuetify.breakpoint.mdAndUp ? 409 : ($vuetify.breakpoint.smAndUp ? 300 : 200)" :hide-delimiters="$vuetify.breakpoint.smAndUp ? false : true" hide-delimiter-background show-arrows-on-hover>
        <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="`https://b2b.yugcontract.ua/fileslibrary/banners/` + slide.file">
            <v-sheet :color="slide.bg_color" height="100%" tile>
                <v-row no-gutters class="fill-height" :align="slide.block_y" :justify="slide.block_x">
                    <v-col cols="12" lg="6" sm="6" :class="`fill-height d-flex flex-column py-4 pb-4 pb-xs-12 py-sm-6 pb-sm-10 py-md-10 pb-md-12 ` + (slide.text_align == 'center' ? 'text-center' : (slide.text_align == 'right' ? `text-right` : `pl-4 pl-xs-12 pl-lg-0 ml-0 ml-sm-6 ml-md-10 ml-lg-12`))" 
                    :offset-sm="slide.block_x == 'end' ? '5' : ''">
                        <v-img v-if="slide.logo_img" :src="slide.logo_img" :max-height="$vuetify.breakpoint.smAndUp ? 35 : 30" contain position="top left"></v-img>
                        <v-container class="fill-height px-0 py-4" transition="slide-x-transition">
                            <div class="align-center">
                                <v-img v-if="slide.titleImg" :src="slide.titleImg" :max-height="$vuetify.breakpoint.smAndUp ? 100 : 50" contain position="top left"></v-img>
                                <div :class="`${slide.title_color}--text b-title `" v-html="slide.title"> </div>
                                <div :class="`${slide.subtitle_color}--text b-subtitle`" v-html="slide.subtitle"></div>
                                <div :class="`${slide.note_color}--text b-caption`" v-html="slide.note"></div>
                            </div>
                        </v-container>
                        <div :class="`px-2 text-` + slide.text_align">
                            <v-btn v-if="slide.btn_text" :class="`${slide.btn_text_color}--text`" :color="slide.btn_color" rounded small :to="slide.to" :href="slide.url" target="_blank">{{ slide.btn_text }} </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-carousel-item>
    </v-carousel>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    data() {
        return {
            ...mapActions(['touch', 'getCarouselBanners']),
            slides: [
                // {
                //     blockX: 'start',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     bgImg: '/img/banners/YUG-NY2022-timeline-3.gif',
                // },

                // {
                //     blockX: 'start',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     btnColor: '#e21e36 ',
                //     btnText: '',
                //     btnTextColor: '',
                //     // logoImg: '/img/banners/ERGO_21_logo.png',
                //     bgImg: '/img/banners/B1.jpg',
                // },
                // {
                //     blockX: 'start',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     btnColor: '#e21e36 ',
                //     btnText: '',
                //     btnTextColor: '',
                //     // logoImg: '/img/banners/ERGO_21_logo.png',
                //     bgImg: '/img/banners/S1.jpg',
                // },


                // {
                //     blockX: 'start',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     btnColor: '#e21e36 ',
                //     btnText: 'Перейти',
                //     btnTextColor: '',
                //     // logoImg: '/img/banners/ERGO_21_logo.png',
                //     bgImg: '/img/banners/ERGO_WebOS.jpg',
                //     link: '/list/360?brands=357'
                // },
                // {
                //     blockX: 'center',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     btnColor: 'black',
                //     btnText: 'Перейти',
                //     btnTextColor: 'white',
                //     textX: 'center',
                //     bgImg: '/img/banners/RINGEL_2022.jpg',
                //     link: '/list/155?brands=6375'
                // },
                // {
                //     blockX: 'center',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     textX: 'center',
                //     btnColor: '#000 ',
                //     btnText: 'Перейти',
                //     btnTextColor: 'white',
                //     // logoImg: '/img/banners/ERGO_21_logo.png',
                //     bgImg: '/img/banners/ERGO_Twins.jpg',
                //     link: '/list/392?brands=357&type=new&props=780_16200'
                // },
                // {
                //     blockX: 'start',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     textX: 'left',
                //     btnColor: '#e21e36',
                //     btnText: 'Перейти',
                //     btnTextColor: '',
                //     bgImg: '/img/banners/Total.jpg',
                //     link: '/list/1035?brands=5586&available=yes'
                // },
                // {
                //     blockX: 'start',
                //     blockY: 'center',
                //     title: '',
                //     bgColor: 'transparent',
                //     titleColor: '',
                //     subtitleColor: '',
                //     btnColor: '#fff',
                //     btnText: 'Перейти',
                //     btnTextColor: 'black',
                //     bgImg: '/img/banners/AMD_20220325.jpg',
                //     link: '/list/26?props=28_13454.13808.13813.17973'
                // },
                // {
                //     blockX: 'start',
                //     blockY: 'center',
                //     title: 'НОВЕ<br>ЗОБРАЖЕННЯ',
                //     subtitle: 'БІЛЬШЕ ВРАЖЕННЯ',
                //     bgColor: 'transparent',
                //     titleColor: 'white',
                //     subtitleColor: 'white',
                //     btnColor: '#e21e36 ',
                //     btnText: 'ДІЗНАТИСЯ БІЛЬШЕ',
                //     btnTextColor: '',
                //     titleImg: '/img/banners/Samsung_Rozetka.jpg',
                //     note: '* Рекомендована роздрібна ціна зі знижкою до -20%<br>в період з 14.12.2020 по 28.12.2020.<br>Подробиці дізнайся у свого менеджера',
                //     bgImg: '/img/banners/ergo_b2b.jpg',
                //     logoImg: '/img/banners/ERGO_21_logo.png',
                //     link: '/list/360?brands=357'
                // }
            ],
        }
    },
    methods: {},
    created() {
        this.touch()
            .then(data => {
                this.getCarouselBanners({
                        placeId: 1
                    })
                    .then(data =>
                        this.slides = data
                    )
            })
            .catch(error => console.error(error))

    },
    computed: {},
}
</script>

<style>
.b-title {
    font-size: 40px !important;
    font-weight: bold;
    line-height: 40px;
}

.b-subtitle {
    font-size: 25px;
    line-height: 35px;
}

.b-caption {
    font-size: 10px;
    line-height: 15px;
    margin-top: 20px;
}

@media(max-width: 599px) {
    .b-title {
        font-size: 25px !important;
        line-height: 25px;
    }

    .b-subtitle {
        font-size: 15px;
        line-height: 20px;
    }

    .b-caption {
        line-height: 12px;
    }
}
</style>
