import {
    HTTP,
} from '../http-common'


export default {
    state: {
        list: {},
        allList: {},
        total: 0,
        loading: false,
        bonusList: [],
        bonusListLoading: false,
        allBonusList: [],
        cPeriod: null,
        motivationFiltersShow: false,
    },
    mutations: {
        setMotivationListAvl(state, payload) {
            state.list = payload
        },
        setAllMotivationList(state, payload) {
            state.allList = payload
        },
        setBonusList(state, payload) {
            state.bonusList = payload
        },
        setBonusListLoading(state, payload) {
            state.bonusListLoading = payload
        },
        setAllBonusList(state, payload) {
            state.allBonusList = payload
        },
        setPeriod(state, payload) {
            state.cPeriod = payload
        },
        setMotivationFiltersShow(state, payload) {
            state.motivationFiltersShow = payload
        }
        
    },
    actions: {
        getContactMotivationList({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/motivation/get-available-contact-motivation')
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setMotivationListAvl', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getAllMotivations({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/motivation/get-all-motivations')
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setAllMotivationList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getMotivationsBonusList({
            commit,
        }, params) {
            return new Promise((resolve, reject) => {
                commit('setBonusListLoading', true)
                // commit('setBonusList', [])
                HTTP.get('/motivation/get-bonus-list', {
                        params
                    })
                    .then(response => {
                        commit('setBonusListLoading', false)
                        if (response.data && response.data.content) {
                            if (params.period){
                                commit('setBonusList', response.data.content)
                            } else {
                                commit('setAllBonusList', response.data.content)
                            }
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        commit('setBonusListLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        }
    }
}
