<template>
<v-dialog v-model="open && !this.userInfo().is_wholesale_onbehalf_ok" persistent max-width="600">
    <InfoBlock type="dialog" />
</v-dialog>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

import InfoBlock from './InfoBlock.vue'

export default {
    components: {
        InfoBlock
    },
    data: () => ({

    }),
    methods: {
        ...mapGetters(['userInfo']),
    },
    computed: {
        open() {
            return this.$store.state.main.infoBlockDialog && (this.data ? true : false)
        },
        data() {
            if (this.$store.state.main.infoBlocks)
                return this.$store.state.main.infoBlocks.find(el => el.format == 'popup')
        }

    },
    watch: {},
}
</script>
