<template>
<v-dialog v-model="open" persistent max-width="600" :fullscreen="$vuetify.breakpoint.xs ? true : false">
    <v-card>
        <v-card-title class="px-2 px-sm-4">
            Рекомендуємо звірити взаєморозрахунки
        </v-card-title>
        <v-card-text class="pb-0 px-2 px-sm-4">
            <div v-for="item in items" :key="item.id" class="">
                <v-list-item class="pa-0 subtitle-2">
                    <v-list-item-subtitle class="second--text">
                        Остання дата звірки по контрагенту <b>{{ item.name }}</b> - <b>{{ fixDate(item['calc_min_date']) }}</b>
                    </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="item['to_cancel_days'] <= 7 && item['to_cancel_days'] > 0" class="pa-0 mb-4 subtitle-2">
                    <v-list-item-subtitle>
                        Вже через <span class="title primary--text">{{Math.abs(item['to_cancel_days'])}}</span> днів спливає термін звірки взаєморозрахунків по контрагенту.
                    </v-list-item-subtitle>
                </v-list-item>
                <v-alert v-if="item['to_cancel_days'] <= 7 && item['to_cancel_days'] > 0" type="error" outlined class="black--text subtitle-2">
                    Якщо звірка не буде вчасно проведена, то ми вимушені будемо заблокувати відвантаження.
                </v-alert>
                <v-list-item v-if="item['to_cancel_days'] <= 0" class="pa-0">
                    <v-list-item-subtitle class="subtitle-2 primary--text">
                        Термін звірки взаєморозрахунків по контрагенту сплив!
                    </v-list-item-subtitle>
                </v-list-item>
                <v-alert v-if="item['to_cancel_days'] <= 0" type="error" class="my-4 subtitle-2">
                    Відвантаження заблоковано!</v-alert>
                <v-list-item-content>
                    Ваші замовлення будуть прийняті, але відвантаження будуть можливі після підтвердження звірки.
                </v-list-item-content>
                <v-list-item class="pa-0">
                    <v-list-item-subtitle class="text-right"><a class="primary--text caption text-decoration-underline" @click="gotoDirect()">Звірити взаєморозрахунки</a></v-list-item-subtitle>
                    <v-list-item-action>
                        <a @click="gotoDirect()" small icon >
                            <v-icon color="primary">trending_flat</v-icon>
                        </a>
                    </v-list-item-action>
                </v-list-item>
            </div>

        </v-card-text>
        <v-card-actions>
            <v-btn @click="open=false" text>Закрити</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    components: {},
    data: () => ({}),
    methods: {
        ...mapGetters(['userInfo']),
        fixDate(date) {
            let d = new Date(date)
            const m = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
            return ('0' + d.getDate()).slice(-2) + '-' + m[d.getMonth()] + '-' + d.getFullYear()
        },
        gotoDirect() {
            this.$store.commit('setDialogOpened', false)
            this.$router.push('/balance?direct')
        }
    },
    computed: {
        calcDate() {
            return this.$store.state.balance.calcDate
        },
        balanceDogs() {
            return this.$store.state.balance.dogs
        },
        expiredBalance() {
            let expire = false
            for (let contractor in this.balanceDogs) {
                if (this.balanceDogs[contractor].find(el => el.exp_date == 1)) {
                    expire = true
                }
            }
            return expire
        },
        open: {
            get: function () {
                return this.$store.state.balance.dialogOpened && this.show
            },
            set: function (newValue) {
                this.$store.commit('setDialogOpened', newValue)
            }
        },
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        items() {
            return this.$store.state.main.saldoInformer
        },
        show() {
            return this.$store.state.main.saldoInformer && this.$store.state.main.saldoInformer.find(el => el['to_cancel_days'] <= 7) &&
                this.contactInfo && this.contactInfo.groups && this.contactInfo.groups.cladmin_finconfirm == 1 && this.contactInfo.isConfirmed ? true : false
        }

    },
    watch: {},
}
</script>

<style>
.v-card__text, .v-card__title, .v-list-item__subtitle {
  word-break: normal; /* maybe !important  */
  white-space: normal;
}
</style>
