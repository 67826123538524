import {
    HTTP
} from '../http-common'


export default {
    state: {
        calcDate: [],
        dogs: {},
        data: {},
        dialogOpened: true,
        showBalanceInformer: false
    },
    mutations: {
        setCalcDate(state, payload) {
            state.calcDate = payload
        },
        setDogs(state, payload) {
            state.dogs = payload
        },
        setData(state, payload) {
            state.data = payload
        },
        setDialogOpened(state, payload) {
            state.dialogOpened = payload
        },
        setShowBalanceInformer(state, payload) {
            state.showBalanceInformer = payload
        },
    },
    actions: {
        ping({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/balance/ping' )
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        Reconciliation ({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/balance/reconciliation' )
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setCalcDate', response.data.content.calcDate)
                            commit('setDogs', response.data.content.dogs)
                            commit('setData', response.data.content.data)
                            resolve(response.data.content)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        createReportToken(i, f) {
            return new Promise((resolve, reject) => {
                HTTP.post('/balance/create-report-token', f )
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            reject(e)
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getBalanceInformer ({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/balance/get-balance-informer' )
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                            commit('setShowBalanceInformer', response.data.content.show || false)
                        } else {
                            resolve({})
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },

    }
}