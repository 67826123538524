<template>
<div v-if="show" class="pa-1">
    <v-alert dense text color="error" border="left" class="pa-0 mb-0">
        <v-list-group prepend-icon="monetization_on" class="">
            <template v-slot:activator>
                <v-list-item-title class="primary--text subtitle-2">Погашення заборгованості</v-list-item-title>
            </template>
            <v-divider class="mb-2 mx-4"></v-divider>
            <!-- <v-list-item>
            <v-list-item-content color="white">
                <v-list-item-title class="subtitle-1">Погашення заборгованості</v-list-item-title>
            </v-list-item-content>
        </v-list-item> -->
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>На {{info.date}} ваша заборгованість складає:</v-list-item-subtitle>
                    <v-list-item-title class="subtitle-2">{{info.expired_debt.toLocaleString('ru-RU')}} грн</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>До погашення сьогодні:</v-list-item-subtitle>
                    <v-list-item-title class="subtitle-2">{{info.today_debt.toLocaleString('ru-RU')}} грн</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="info.next_debt > 0">
                <v-list-item-content>
                    <v-list-item-subtitle>Найближчий плановий платіж:</v-list-item-subtitle>
                    <v-list-item-subtitle>на {{fixDate(info.next_debt_date)}}</v-list-item-subtitle>
                    <v-list-item-title class="subtitle-2">{{info.next_debt.toLocaleString('ru-RU')}} грн</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Повна заборгованість:</v-list-item-subtitle>
                    <v-list-item-title class="subtitle-2">{{info.total_debt.toLocaleString('ru-RU')}} грн</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-subtitle class="text-right">
                    <router-link class="black--text caption" to="/payment">Перейти до графіку погашення боргу</router-link>
                </v-list-item-subtitle>
                <v-list-item-action>
                    <v-icon>trending_flat</v-icon>
                </v-list-item-action>
            </v-list-item>
        </v-list-group>
    </v-alert>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    components: {},
    data: () => ({

    }),
    props: {},
    methods: {
        ...mapGetters(['userInfo']),
        ...mapActions([]),
        fixDate(date) {
            let d = new Date(date)
            const m = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
            return ('0' + d.getDate()).slice(-2) + '-' + m[d.getMonth()] + '-' + d.getFullYear()
        }
    },
    created() {},
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        info() {
            return this.$store.state.main.deptInformer
        },
        show() {
            return this.contactInfo && this.contactInfo.groups && this.contactInfo.groups.cl_payment == 1 && this.info.total_debt > 100 ? true : false
        }
    }
}
</script>

<style>

</style>
