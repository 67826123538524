import {
    HTTP
} from '../http-common'


export default {
    state: {
        list: [],
        total: 0,
        loading: false,
        contractors: [],
        documentData: {},
        listGoods: [],
        totalGoods: 0,
        categories: [],
    },
    mutations: {
        setList(state, payload) {
            state.list = payload
        },
        setTotal(state, payload) {
            state.total = payload
        },
        setGoodsList(state, payload) {
            state.listGoods = payload
        },
        setGoodsTotal(state, payload) {
            state.totalGoods = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setContractors(state, payload) {
            state.contractors = payload
        },
        setDocumentData(state, payload) {
            state.documentData = payload
        },
        setCategories(state, payload) {
            state.categories = payload
        },
    },
    actions: {
        salesList(
        {
            commit,
        }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/sales/list', {params} )
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setList', response.data.content.salesList)
                            commit('setTotal', response.data.content.totalList)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        salesGoodsList(
        {
            commit,
        }, options) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/sales/goods-list', {params} )
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setGoodsList', response.data.content.salesGoodsList)
                            commit('setGoodsTotal', response.data.content.totalGoodsList)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getClientContractors({commit}){
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.get('/sales/get-contractors',)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setContractors', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getCategories({commit}){
            return new Promise((resolve, reject) => {
                HTTP.get('/sales/get-categories',)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setCategories', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getDocumentData({commit}, options){
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                const params = {
                    ...options
                }
                HTTP.get('/sales/get-document-data',  {params} )
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content) {
                            commit('setDocumentData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },
    }
}