import {
    HTTP,
    HTTP_AUTH
} from '../http-common'

const snackbarDefaults = {
    success: {
        color: 'accent2 darken-1',
        icon: 'done_outline',
        text: ''
    },
    info: {
        color: 'info',
        icon: 'done_outline',
        text: ''
    },
    error: {
        color: 'error',
        icon: 'error',
        text: 'Помилка! '
    },
    warning: {
        color: 'warning',
        icon: 'warning',
        text: ''
    },
}

export default {
    state: {
        error: false,
        userInfo: {},
        contactInfo: {},
        userInfoLoading: false,
        navigationDrawerOpened: false,
        navigationDrawerRightOpened: false,
        onbehalfDialog: false,
        emptyClientDialog: false,
        now: Date.now(),
        snackbar: {
            model: false,
            color: '',
            icon: '',
            text: ''
        },
        catalogTree: {},
        catalogTreeLoading: false,
        loading: false,
        breadCrumbs: {},
        ratingDialog: false,
        currRating: 0,
        ratingDate: undefined,
        todayRated: false,
        saldoInformer: [],
        deptInformer: [],
        infoBlockDialog: true,
        infoBlock: true,
        infoBlockText: true,
        infoBlockMain: true,
        infoBlocks: [],
        shoppingCartDialog: false,
        shoppingCartDialogTab: 0,
        shoppingCartCounter: 0,
        shoppingCartAvailable: [],
        shoppingCartReserve: [],
        shoppingCartAbsent: [],
        shoppingCartPreorder: [],
        shoppingCartFromBackend: [],
        secretPare: {},
        allowedUserIp: undefined,
        is_withoutIP: false,
        shoppingPacketList: [],
        shoppingCartData: [],
        newClientPageData: {},
        contactsList: [],
        managersList: [],
        contractorsReserve: {},
        bannersList: [],
        bannersPlacementList: [],
        bannersPlaceList: [],
        regionsList: [],
        scList: [],
        headers: [{
            text: 'У кошик',
            static: true,
            align: 'center',
            value: 'cnt',
            selected: true,
            hidden: true,
            sortable: false,
            width: '5%',
            left: true
        },

        {
            text: 'Код',
            static: true,
            align: 'center',
            sortable: true,
            value: 'typhoon_id',
            selected: true,
            width: '5%',
        },
        {
            text: 'Артикул',
            align: 'center',
            sortable: true,
            value: 'artikul',
            selected: false,
            width: '5%',
            dynamic: true
        },
        {
            text: 'Фото',
            align: 'center',
            value: 'smallimg',
            selected: true,
            sortable: false,
            width: '10%',
        },
        {
            text: 'Бренд',
            value: 'brand_name',
            selected: false,
            sortable: true,
            width: '5%',
        },
        {
            text: 'Модель',
            align: 'left',
            sortable: true,
            value: 'model',
            selected: false,
            width: '10%',
        },
        {
            text: 'Назва',
            value: 'name',
            selected: false,
            sortable: true,
            width: '40%',
        },
        {
            text: 'Назва укр.',
            static: true,
            value: 'name_ukr',
            selected: true,
            sortable: true,
            width: '40%',
        },
        {
            text: 'Опис',
            value: 'descr_typhoon',
            selected: false,
            sortable: false,
            virtual: true
        },
        {
            text: 'Категорія',
            value: 'b2b_name',
            selected: false,
            sortable: true,
            width: '10%',
        },
        {
            text: 'Код ЗЕД',
            align: 'center',
            sortable: false,
            value: 'fea_code',
            selected: true,
            width: '10%',
            dynamic: true

        },
        {
            text: 'Штрих-код',
            align: 'center',
            sortable: true,
            value: 'barcode',
            selected: false,
            width: '10%',
            dynamic: true
        },
        {
            text: 'Аргументація',
            align: 'center',
            sortable: false,
            value: 'argument',
            selected: true,
            static: true,
            width: '25%',
            hidden: true
        },
        {
            text: 'Цiна ₴',
            value: 'price_uah',
            align: 'right',
            selected: true,
            sortable: true,
            width: '10%',
        },
        {
            text: 'РРЦ ₴',
            value: 'price_rrc',
            align: 'right',
            selected: true,
            sortable: true,
            width: '10%',
        },
        {
            text: 'РРНац, %',
            value: 'markup',
            align: 'center',
            selected: false,
            sortable: true,
            width: '5%',
        },
        {
            text: 'Країна',
            value: 'country',
            align: 'center',
            selected: false,
            sortable: true,
            width: '10%',
            dynamic: true

        },
        {
            text: 'Склад',
            value: 'availability',
            align: 'center',
            selected: true,
            sortable: true,
            width: '10%',
            hidden: true
        },
        {
            text: 'Пакув. (шт.)',
            // static: true,
            align: 'center',
            value: 'pack',
            selected: true,
            sortable: true,
            width: '5%',
        },
        {
            text: 'Блок (шт.)',
            align: 'center',
            value: 'pack_block',
            selected: false,
            sortable: true,
            width: '5%',
        },
        {
            text: 'Кор. (шт.)',
            align: 'center',
            value: 'pack_box',
            selected: false,
            sortable: true,
            width: '5%',
        },
        {
            text: 'Палл. (шт.)',
            align: 'center',
            value: 'pack_pallet',
            selected: false,
            sortable: true,
            width: '5%',
        },
        {
            text: 'Гар. терм.',
            align: 'left',
            value: 'warranty',
            selected: false,
            sortable: true,
            width: '5%',
            dynamic: true
        },
        {
            text: 'Резерв',
            align: 'center',
            value: 'reserve',
            selected: false,
            sortable: false,
            width: '5%',
        },
        // {
        //     text: 'Статус',
        //     align: 'center',
        //     value: 'status',
        //     selected: true,
        //     sortable: true,
        //     width: '5%',
        // },
        {
            text: 'У кошик',
            static: true,
            align: 'center',
            value: 'cnt',
            selected: true,
            hidden: false,
            sortable: false,
            width: '5%',
            left: false
        },
        ],

    },
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setContactInfo(state, payload) {
            state.contactInfo = payload
        },
        updateTime(state) {
            state.now = Date.now()
        },
        updateSnackbar(state, payload) {
            state.snackbar = payload
        },
        openCloseNavigationDrawer(state, payload) {
            state.navigationDrawerOpened = payload
        },
        openCloseNavigationDrawerRight(state, payload) {
            state.navigationDrawerRightOpened = payload
        },
        setCatalogTree(state, payload) {
            state.catalogTree = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setBreadCrumbs(state, payload) {
            state.breadCrumbs = payload
        },
        setOnbehalfDialog(state, payload) {
            state.onbehalfDialog = payload
        },
        setEmptyClientDialog(state, payload) {
            state.emptyClientDialog = payload
        },
        setRatingDialog(state, payload) {
            state.ratingDialog = payload
        },
        setCurrRating(state, payload) {
            state.currRating = payload
        },
        setRatingDate(state, payload) {
            state.ratingDate = payload
        },
        setTodayRated(state, payload) {
            state.todayRated = payload
        },
        setSaldoInformer(state, payload) {
            state.saldoInformer = payload
        },
        setDeptInformer(state, payload) {
            state.deptInformer = payload
        },
        setInfoBlockDialog(state, payload) {
            state.infoBlockDialog = payload
        },
        setInfoBlock(state, payload) {
            state.infoBlock = payload
        },
        setInfoBlockText(state, payload) {
            state.infoBlockText = payload
        },
        setInfoBlockMain(state, payload) {
            state.infoBlockMain = payload
        },
        setInfoBlocks(state, payload) {
            state.infoBlocks = payload
        },
        setShoppingCartDialog(state, payload) {
            state.shoppingCartDialog = payload
        },
        setShoppingCartDialogTab(state, payload) {
            state.shoppingCartDialogTab = payload
        },
        setShoppingCartCounter(state, payload) {
            state.shoppingCartCounter = payload
        },
        setShoppingCartAvailable(state, payload) {
            state.shoppingCartAvailable = payload
        },
        setShoppingCartPreorder(state, payload) {
            state.shoppingCartPreorder = payload
        },
        setShoppingCartReserve(state, payload) {
            state.shoppingCartReserve = payload
        },
        setShoppingCartAbsent(state, payload) {
            state.shoppingCartAbsent = payload
        },
        setShoppingCartFromBackend(state, payload) {
            state.shoppingCartFromBackend = payload
        },
        setSecret(state, payload) {
            state.secretPare = payload
        },
        setAllowedUserIp(state, payload) {
            state.allowedUserIp = payload
        },
        setIs_withoutIP(state, payload) {
            state.is_withoutIP = payload
        },
        setShoppingPacketList(state, payload) {
            state.shoppingPacketList = payload
        },
        set_ShoppingCartData(state, payload) {
            state.shoppingCartData = payload
        },
        setNewClientPageData(state, payload) {
            state.newClientPageData = payload
        },
        setHeaders(state, payload) {
            state.headers = payload
        },
        setContactsList(state, payload) {
            state.contactsList = payload
        },
        setContractorsReserve(state, payload) {
            state.contractorsReserve = payload
        },
        setManagersList(state, payload) {
            state.managersList = payload
        },
        setBannersList(state, payload) {
            state.bannersList = payload
        },
        setBannersPlacementList(state, payload) {
            state.bannersPlacementList = payload
        },
        setBannersPlaceList(state, payload) {
            state.bannersPlaceList = payload
        },
        setRegionsList(state, payload) {
            state.regionsList = payload
        },
        setScList(state, payload) {
            state.scList = payload
        },
    },
    actions: {
        getUserInfo({
            commit,
            state,
            dispatch
        }) {
            state.userInfoLoading = true
            state.loading = true
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/user-info')
                    .then(response => {
                        state.userInfoLoading = false
                        state.loading = false
                        commit('setUserInfo', response.data.content)
                        if (!response.data || !response.data.content || response.data.content.id == 0 || !response.data.content.id) {
                            const data = response.data.content
                            data.status = 401
                            resolve(data)
                        } else {
                            resolve(response.data.content)
                        }
                    })
                    .catch(error => {
                        state.userInfoLoading = false
                        state.loading = false
                        state.error = true
                        if (error.response && error.response.status !== 401) {
                            dispatch('setSnackbar', ['error', error])
                        }
                        reject(error)
                    })
            })
        },
        getContactInfo({
            commit,
            state,
            dispatch
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/auth/contact-info')
                    .then(response => {
                        commit('setContactInfo', response.data.content)
                        resolve(response.data.content)
                    })
                    .catch(error => {
                        state.error = true
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getCatalogTree({
            commit, state
        }) {
            state.catalogTreeLoading = true
            state.loading = true
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/tree')
                    .then(response => {
                        state.catalogTreeLoading = false
                        state.loading = false
                        commit('setCatalogTree', response.data.data)
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        state.catalogTreeLoading = false
                        state.loading = false
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getCatalogPromuaTree({
            commit, state
        }) {
            state.catalogTreeLoading = true
            state.loading = true
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/promua-tree')
                    .then(response => {
                        state.loading = false
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        state.catalogTreeLoading = false
                        state.loading = false
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getBreadCrumbs({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/bread-crumbs')
                    .then(response => {
                        resolve(response.data.data)
                        commit('setBreadCrumbs', response.data.data)
                    })
                    .catch(error => {
                        console.error(error)
                    })
            })
        },
        start({
            commit
        }) {
            setInterval(() => {
                commit('updateTime')
            }, 1000 * 60)
        },
        setSnackbar({
            commit
        }, [type, text, alert]) {
            let data = {
                ...snackbarDefaults[type],
                text: snackbarDefaults[type || 'error'].text + (text || ''),
                alert,
                model: true
            }
            commit('updateSnackbar', data)
        },
        logOut(c) {
            return new Promise((resolve, reject) => {
                HTTP_AUTH.delete('/auth/logout')
                    .then(response => {
                        localStorage.removeItem("authToken")
                        localStorage.removeItem("refreshToken")
                        c.commit('setUserInfo', {
                            id: 0
                        })
                        resolve(response)
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            }
            )
        },
        logIn(c, data) {
            return new Promise((resolve, reject) => {
                HTTP_AUTH.post('/auth/login', data)
                    .then(response => {
                        resolve(response.data.content)
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            }
            )
        },
        touch(c) {
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/touch')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            }
            )
        },
        verifyByPhone(c, data) {
            return new Promise((resolve, reject) => {
                HTTP_AUTH.post('/auth/verify-by-phone', data)
                    .then(response => {
                        resolve(response.data.content)
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            }
            )
        },
        verify(c, data) {
            return new Promise((resolve, reject) => {
                HTTP_AUTH.post('/auth/verify-code', data)
                    .then(response => {
                        resolve(response.data.content)
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            }
            )
        },
        productPageVisited({
        }, id) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/product-page-visited', { id })
                    .then(response => {
                        resolve()
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        catalogVisited({
        }, url) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/catalog-visited', { url })
                    .then(response => {
                        resolve()
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        setOnbehalf({
        }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/set-onbehalf', data)
                    .then(response => {
                        resolve()
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        printScreen({
        }, url) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/print-screen', { url })
                    .then(response => {
                        resolve()
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendContactInfo({ commit
        }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/send-contact-info', data)
                    .then(response => {
                        resolve()
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        setInfoBlock({ commit
        }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/info/send-info-block', data)
                    .then(response => {
                        resolve(data)
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        setInfoBlockView({ commit
        }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/info/send-info-block-view', data)
                    .then(response => {
                        resolve(data)
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        sendRating({ commit
        }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/info/send-rating', data)
                    .then(response => {
                        commit('setCurrRating', response.data.content.rating)
                        commit('setRatingDate', new Date())
                        commit('setTodayRated', true)
                        resolve()
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getRating({ commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/info/get-rating')
                    .then(response => {
                        if (response.data.content && response.data.content.mark) {
                            commit('setCurrRating', response.data.content.mark)
                            commit('setRatingDate', response.data.content.date)
                            commit('setTodayRated', response.data.content.todayRated == 1 ? true : false)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getInfoBlocks({ commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/info/get-info-blocks')
                    .then(response => {
                        if (response.data.content) {
                            commit('setInfoBlocks', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getSaldoInformer({ commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/info/get-saldo-informer')
                    .then(response => {
                        if (response.data.content && response.data.content) {
                            commit('setSaldoInformer', response.data.content.out)
                            resolve(response.data.content.out)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getDeptInformer({ commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/info/get-dept-informer')
                    .then(response => {
                        if (response.data.content && response.data.content) {
                            commit('setDeptInformer', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getShoppingCart({
            commit,
            state,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/get-shopping-cart')
                    .then(response => {
                        if (response.data.content && response.data.content.data) {
                            commit('setShoppingCartFromBackend', response.data.content.data)
                            resolve(response.data.content.data)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(error => {
                        state.error = true
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getShoppingCartData({
            commit,
            state,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/get-shopping-cart-data')
                    .then(response => {
                        if (response.data.content) {
                            commit('set_ShoppingCartData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }

                    })
                    .catch(error => {
                        state.error = true
                        console.error(error)
                        reject(error)
                    })
            })
        },
        setShoppingCartData({
            state,
        }, params) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/set-shopping-cart-data', params)
                    .then(response => {
                        resolve()
                    })
                    .catch(error => {
                        state.error = true
                        console.error(error)
                        reject(error)
                    })
            })
        },
        createSecret({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/create-secret')
                    .then(response => {
                        if (response.data.content) {
                            commit('setSecret', response.data.content)
                            resolve(response.data.content)
                        }
                    })
                    .catch(error => {
                        state.error = true
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getSecretPare({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/auth/get-secret-pare')
                    .then(response => {
                        if (response.data.content) {
                            commit('setSecret', response.data.content)
                            resolve(response.data.content)
                        }
                    })
                    .catch(error => {
                        state.error = true
                        console.error(error)
                        reject(error)
                    })
            })
        },
        authAPIRequest({ }, params) {
            return new Promise((resolve, reject) => {
                HTTP_AUTH.post('/auth/get-auth-token', params)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        postMethodRequest({ }, { method, params }) {
            return new Promise((resolve, reject) => {
                HTTP.post(method, params)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        savePromUA({ }, data) {
            return new Promise((resolve, reject) => {
                HTTP.post('/catalog/save-prom-ua', data)
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getPromUa({ }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/get-prom-ua')
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        getAllowedUserIp({ commit }) {
            return new Promise((resolve, reject) => {
                HTTP.post('/auth/get-allowed-user-ip')
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                            commit('setAllowedUserIp', response.data.content.rows)
                            commit('setIs_withoutIP', response.data.content.is_withoutIP)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        reject(error)
                    })
            })
        },
        loadShoppingPacket({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/catalog/load-shopping-packet', data)
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            resolve(response.data.content)
                            commit('setShoppingPacketList', response.data.content)
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        getNewClientPageData({ commit, state }) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.get('/open/get-new-client-page-data')
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            resolve(response.data.content)
                            commit('setNewClientPageData', response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        addNewClient({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/open/add-new-client', data)
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        forgotPassword({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/open/forgot-password', data)
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        getContactsList({ commit, state }) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.get('/auth/get-contacts-list')
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            commit('setContactsList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        getManagersList({ commit, state }) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.get('/auth/get-managers-list')
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            commit('setManagersList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        sendConcactInfo({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/auth/send-contact-info-message', data)
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        confirmContact({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/auth/confirm-contact', data)
                    .then(response => {
                        state.loading = false
                        if (response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        state.loading = false
                        reject(error)
                    })
            })
        },
        getContractorsReserve({ commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/catalog/get-contractors-reserve')
                    .then(response => {
                        if (response.data.content && response.data.content) {
                            commit('setContractorsReserve', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getStaticBanners({ }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/banners/get-static-banner?placeId=' + data.placeId)
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        state.loading = false
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getCarouselBanners({ }, data) {
            return new Promise((resolve, reject) => {
                HTTP.get('/banners/get-carousel-banner?placeId=' + data.placeId)
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        state.loading = false
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getBannersList({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.get('/banners/get-banners-list')
                    .then(response => {
                        if (response.data.content) {
                            commit('setBannersList', response.data.content)
                            resolve(response.data.content)
                            state.loading = false
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        state.loading = false
                        reject(e)
                    })
            })
        },
        getBannersPlacementList({ commit, state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.get('/banners/get-banners-placement-list')
                    .then(response => {
                        if (response.data.content) {
                            commit('setBannersPlacementList', response.data.content)
                            resolve(response.data.content)
                            state.loading = false
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        state.loading = false
                        reject(e)
                    })
            })
        },
        saveBanner({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/banners/save-banner', data)
                    .then(response => {
                        state.loading = false
                        resolve()
                    })
                    .catch(e => {
                        state.loading = false
                        console.error(e)
                        reject(e)
                    })
            })
        },
        saveBannerPlacement({ state }, data) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/banners/save-banner-placement', data)
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                            state.loading = false
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        state.loading = false
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getBannersPlaceList({ commit, state }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/banners/get-banners-place-list')
                    .then(response => {
                        if (response.data.content) {
                            commit('setBannersPlaceList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getRegionsList({ commit, state }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/banners/get-regions-list')
                    .then(response => {
                        if (response.data.content) {
                            commit('setRegionsList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getScList({ commit, state }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/banners/get-sc-list')
                    .then(response => {
                        if (response.data.content) {
                            commit('setScList', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        deleteBannerPlacement({ state }, id) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/banners/delete-banner-placement', { id })
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                            state.loading = false
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        state.loading = false
                        console.error(e)
                        reject(e)
                    })
            })
        },
        deleteBanner({ state }, id) {
            return new Promise((resolve, reject) => {
                state.loading = true
                HTTP.post('/banners/delete-banner', { id })
                    .then(response => {
                        if (response.data.content) {
                            resolve(response.data.content)
                            state.loading = false
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        state.loading = false
                        console.error(e)
                        reject(e)
                    })
            })
        },
    },
    getters: {
        userInfo(state) {
            return state.userInfo
        },
        catalogTree(state) {
            return state.catalogTree
        },
        snackbar(state) {
            return state.snackbar
        },
        NOW(state) {
            return state.now
        }
    }
}