<template>
<div class="px-2"> 
    <div v-show="items.length && !loading" :class="`title pl-4 pb-4 d-flex justify-space-between `+ (titleColor ? titleColor : `primary`)+`--text`">
        <div class="title">{{title}}</div>
        <div v-if="bonus" class="d-flex align-end caption"><router-link to="/bonus/">Всі бонусні пропозиції<v-icon class="pr-1 mb-1" color="primary">trending_flat</v-icon></router-link></div>
    </div>
    
    <div class="text-center pa-10" v-show="loading">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
    </div>
    
    <v-carousel :height="hidePrice? 380 : 455" hide-delimiters v-show="items.length" >
        <v-carousel-item v-for="i in totalSlides" :key="i">
            <SliderItem :items="items" :i="i" :totalSlides="totalSlides" :loading="loading" :sliderCols="sliderCols" :hide-price="hidePrice" :bonus="bonus"/>
        </v-carousel-item>
    </v-carousel>
</div>
</template>

<script>
import SliderItem from './SliderItem.vue'

export default {
    components: {
        SliderItem
    },
    props: {
        items: Array,
        title: String,
        'hide-price': Boolean,
        loading: Boolean,
        bonus: Boolean,
        titleColor: String
    },
    data() {
        return {}
    },
    methods: {},
    created() {},
    computed: {
        sliderCols() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 1
                case 'sm':
                    return 3
                case 'md':
                    return 4
                case 'lg':
                    return 4
                case 'xl':
                    return 5
            }
        },
        totalSlides() {
            const div = this.items.length % this.sliderCols
            return parseInt(this.items.length / this.sliderCols + (div ? 1 : 0))
        }
    },
}
</script>

<style scoped>
</style>
