<template>
<div v-show="items.length" class="px-3">
    <div class="title pl-4 pb-4 primary--text">{{title}}</div>

    <div v-for="i in totalSlides" :key="i" style="height: 410px" v-intersect="{
              handler: onIntersect,
              options: {
                threshold: [0, 0.5, 1.0]
              }
            }">
        <SliderItem :items="items" :i="i" :totalSlides="totalSlides" :sliderCols="sliderCols" />
    </div>
    <div class="text-center pa-10" v-if="loading">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
    </div>
</div>
</template>

<script>
import SliderItem from './SliderItem.vue'
import {
    mapActions
} from 'vuex'

export default {
    components: {
        SliderItem
    },
    props: {
        title: String
    },
    data() {
        return {
            isIntersecting: false,
            maxItems: 200,
            loading: false
        }
    },
    methods: {
        ...mapActions(['getRecommendedItems', 'touch']),
        onIntersect(entries, observer, isIntersecting) {
            this.isIntersecting = entries[0].intersectionRatio >= 1
        },
        getData() {
            this.loading = true
            this.touch()
                .then(() => {
                    this.getRecommendedItems(this.sliderCols)
                    .then(() => this.loading = false)
                })
                .catch(error => console.log(error))
        },
        
    },
    watch: {
        isIntersecting: function (newV, oldV) {
            if (newV == true && oldV == false && this.maxItems > this.items.length) {
                this.getData()
            }
        },
    },
    created() {
        this.getData()
    },
    computed: {
        sliderCols() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 1
                case 'sm':
                    return 3
                case 'md':
                    return 4
                case 'lg':
                    return 4
                case 'xl':
                    return 5
            }
        },
        totalSlides() {
            const div = this.items.length % this.sliderCols
            return parseInt(this.items.length / this.sliderCols + (div ? 1 : 0))
        },
        items() {
            return this.$store.state.catalog.recommendedItems
        }
    },
}
</script>

<style scoped>
</style>
