<template>
<div v-if="show" class="pa-1">
    <v-alert dense text color="warning" border="left" class="pa-0 mb-0">
        <v-list-group prepend-icon="alarm_on" class="">
            <template v-slot:activator>
                <v-list-item-title class="primary--text subtitle-2">Звірка протермінована</v-list-item-title>
            </template>
            <v-divider class="mb-2 mx-4"></v-divider>
            <div v-for="item in items" :key="item.id">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Контрагент</v-list-item-subtitle>
                        <v-list-item-title class="subtitle-2" v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-subtitle>Дата останньої звірки</v-list-item-subtitle>
                        <v-list-item-title class="subtitle-2" v-text="fixDate(item['calc_min_date'])"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-alert v-if="item['to_cancel_days'] <= 7 && item['to_cancel_days'] > 0" type="error" outlined class="mx-4 mb-0 black--text subtitle-2">Блокування відвантажень відбудеться через {{item['to_cancel_days']}} днів!</v-alert>
                <v-alert v-if="item['to_cancel_days'] <= 0" type="error" class="mx-4 mb-0 subtitle-2">Звірка протермінована!<br>Відвантаження заблоковані!</v-alert>
                <v-list-item>
                    <v-list-item-subtitle class="text-right"><a class="black--text caption"  @click="$router.push('/balance?direct')">Звірити взаєморозрахунки</a></v-list-item-subtitle>
                    <v-list-item-action>
                        <a @click="$router.push('/balance?direct')" small icon>
                            <v-icon>trending_flat</v-icon>
                        </a>
                    </v-list-item-action>
                </v-list-item>
            </div>
        </v-list-group>
    </v-alert>
</div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    components: {},
    data: () => ({

    }),
    props: {},
    methods: {
        ...mapGetters(['userInfo']),
        ...mapActions([]),
        fixDate(date) {
            let d = new Date(date)
            const m = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
            return ('0' + d.getDate()).slice(-2) + '-' + m[d.getMonth()] + '-' + d.getFullYear()
        }
    },
    created() {},
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
        items() {
            return this.$store.state.main.saldoInformer
        },
        show() {
            return this.$store.state.main.saldoInformer && this.$store.state.main.saldoInformer.find(el => el['to_cancel_days'] <= 7) &&
                this.contactInfo && this.contactInfo.groups && this.contactInfo.groups.cladmin_finconfirm == 1 && this.contactInfo.isConfirmed ? true : false
        }
    }
}
</script>

<style>

</style>
