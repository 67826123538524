<template>
<v-dialog v-model="dialog" persistent width="100%" max-width="600">
    <v-card>
        <v-system-bar color="primary" dark>
        </v-system-bar>
        <v-card-title class="primary--text text-center">Доступ закрито!</v-card-title>
        <v-card-text>
            <p class="font-weight-bold">
                Клієнта не визначено.
            </p>
            <p>Зверніться до менеджера нашої компанії, з яким ви співпрацюєте, або до Адміністраторів потралу для вирішення питання доступу.            </p>
        </v-card-text>
        <v-card-actions>
            <v-list-item>
                <v-list-item-title class="text-right">ВИЙТИ</v-list-item-title>
                <v-list-item-avatar class="my-0">
                    <v-btn icon @click="logout()">
                        <v-icon>logout</v-icon>
                    </v-btn>
                </v-list-item-avatar>
            </v-list-item>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

    
    
<script>
import {
    mapActions
} from 'vuex'

export default {
    data: () => ({}),
    methods: {
        ...mapActions(['logOut', 'touch']),
        logout: function () {
            this.touch()
                .then(() => {
                    this.$store.commit('setEmptyClientDialog', false)
                    this.logOut()
                        .then(response => {
                            this.$router.push({
                                name: 'login'
                            })
                        })
                })
        },
    },
    computed: {
        dialog() {
            return this.$store.state.main.emptyClientDialog
        }
    },
}
</script>
