<template>
<v-container fill-height>
    <v-card elevation="0" width="400" class="mx-auto">
        <v-card-text class="primary--text pb-0">Помилка 404</v-card-text>
        <v-card-title class="pt-0">Сторінку не знайдено!</v-card-title>
        <v-card-text>Неправильно набрано адресу або такої сторінки на сайті більше не існує</v-card-text>
        <v-card-text>Перейдіть на головну сторінку або оберіть потрібну категорію</v-card-text>
    </v-card>
</v-container>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    watch: {},
    mounted() {},
    computed: {}

}
</script>

<style>
</style>
