<template>
<v-flex class="pl-3 pt-2">

    <v-card color="grey darken-1 " class="mb-2" outlined tile height="200" width="100%" :href="topBanner && topBanner.url" target="_blank">
        <v-img :src="topBanner && `https://b2b.yugcontract.ua/fileslibrary/banners/` + topBanner.file" position="center" height="100%" transition="fade-transition"></v-img>
    </v-card>

    <v-card color="grey darken-1 " class="mb-2" outlined tile height="200" width="100%" :href="bottomBanner && bottomBanner.url" target="_blank">
        <v-img :src="bottomBanner && `https://b2b.yugcontract.ua/fileslibrary/banners/` + bottomBanner.file" position="center" height="100%" transition="fade-transition"></v-img>
    </v-card>



</v-flex>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    data() {
        return {
            ...mapActions(['touch', 'getStaticBanners']),
            topBanner: {},
            bottomBanner: {},
        }
    },
    methods: {},
    created() {
        this.touch()
            .then(data => {
                this.getStaticBanners({
                        placeId: 2
                    })
                    .then(data =>
                        this.topBanner = data
                    )
                this.getStaticBanners({
                        placeId: 3
                    })
                    .then(data =>
                        this.bottomBanner = data
                    )
            })
            .catch(error => console.error(error))
    },
    computed: {},
}
</script>
