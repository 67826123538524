import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
import uk from './uk.ts'

export default new Vuetify({
  lang: {
    locales: { uk },
    current: 'uk',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#e50040', //logo red
        second: '#333333',  //font
        third: '#ffb048',   //orange
        accent: '#fcf4db',  //yellow light 
        accent2: '#8BC34A', //light-green mdi 
        link: '#10457d',    //blue dark
        link2: '#62b5e4',   //blue light
        error: '#ec1c24',   //red
        backgr: '#b1c3d2',
      },
    },
  }
});
