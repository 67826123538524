import {
    HTTP,
    HTTP_INTRANET
} from '../http-common'


export default {
    state: {
        list: [],
        total: 0,
        loading: false,
        filters: {},
        filtersDrawerOpened: false,
        contractors: []
    },
    mutations: {
        setList(state, payload) {
            state.list = payload
        },
        setTotal(state, payload) {
            state.total = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setFilters(state, payload) {
            state.filters = payload
        },
        openCloseBonusFiltersDrawer(state, payload){
            state.filtersDrawerOpened = payload
        },
        setContractors(state, payload) {
            state.contractors = payload
        },

    },
    actions: {
        getBonusData({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP.post('/bonus/get-bonus', f)
                    .then(response => {
                        if (response.data)  {
                            resolve(response.data)
                        } else {
                            resolve(response)
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getBonusList({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/bonus/get-bonus-list', f)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data)  {
                            commit('setList', response.data.content.bonusList)
                            commit('setTotal', response.data.content.total)
                            resolve(response.data.content.bonusList)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getBonusFilters(
            {
                commit,
            }, f) {
                return new Promise((resolve, reject) => {
                    commit('setLoading', true)
                    HTTP.post('/bonus/get-filters',  f)
                        .then(response => {
                            commit('setLoading', false)
                            if (response.data && response.data.content) {
                                commit('setFilters', response.data.content)
                                resolve(response.data.content)
                            } else {
                                resolve([])
                            }
                        })
                        .catch(e => {
                            commit('setLoading', false)
                            console.error(e)
                            reject(e)
                        })
                })
        },
        getBonusContractors({commit}){
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.get('/bonus/get-contractors',)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data && response.data.content, response.data.content.contractors) {
                            commit('setContractors', response.data.content.contractors)
                            resolve(response.data.content.contractors)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        commit('setLoading', false)
                        console.error(e)
                        reject(e)
                    })
            })
        },

    }
}