import {
    HTTP,
    HTTP_INTRANET
} from '../http-common'


export default {
    state: {
        regions: [],
        cities:[],
        streets:[],
        selfWh:[],
        orderInfo: {},
        orderedProducts: undefined,
        preorderedProducts: undefined,
        nonOrderedProducts: undefined,
        repairedProducts: undefined,
        priorityWarehouse: 0,
        contractors: [],
        consigneesData: [],
        contractorsDogs: []
    },
    mutations: {
        setRegions(state, payload) {
            state.regions = payload
        },
        setCities(state, payload) {
            state.cities = payload
        },
        setSelfWh(state, payload) {
            state.selfWh = payload
        },
        setOrderInfo(state, payload) {
            state.orderInfo = payload
        },
        setOrderedProducts(state, payload) {
            state.orderedProducts = payload
        },
        setNonOrderedProducts(state, payload) {
            state.nonOrderedProducts = payload
        },
        setPreorderedProducts(state, payload) {
            state.preorderedProducts = payload
        },
        setPriorityWarehouse(state, payload) {
            state.priorityWarehouse = payload
        },
        setRepairedProducts(state, payload) {
            state.repairedProducts = payload
        },
        setStreets(state, payload) {
            state.streets = payload
        },
        setContractors(state, payload) {
            state.contractors = payload
        },
        setContractorsDogs(state, payload) {
            state.contractorsDogs = payload
        },
        setConsigneesData(state, payload) {
            state.consigneesData = payload
        },
    },
    actions: {
        getRegionsAutocomplete({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/order/get-regions' )
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setRegions', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getCitiesAutocomplete({
            commit,
        }, id) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-cities', {id})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setCities', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getStreetsAutocomplete({
            commit,
        }, f ) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-streets', f)
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setStreets', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getDeliverySelfWh({
            commit,
        }, [regionId, cityId]) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-self-wh', {regionId, cityId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setSelfWh', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getCarriers({
            commit,
        }, [regionId, cityId, deliveryMethod]) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-carriers', {regionId, cityId, deliveryMethod})
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getDeliveryWarehouses({
            commit,
        }, [cityId, deliveryCarrier]) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-warehouses', {cityId, deliveryCarrier})
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getOrderContractors({
            commit,
        }) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.get('/order/get-contractors')
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data)  {
                            commit('setContractors', response.data.content.contractors)
                            resolve(response.data.content.contractors)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getOrderContractorsDogs({
            commit,
        }, conf) {
            return new Promise((resolve, reject) => {
                commit('setLoading', true)
                HTTP.post('/order/get-contractors-dogs', conf)
                    .then(response => {
                        commit('setLoading', false)
                        if (response.data)  {
                            commit('setContractorsDogs', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getConsignees({
            commit,
        }, {cityId, contractorId}) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-consignees', {cityId, contractorId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getConsigneesData({ 
            commit
        }) {
            return new Promise((resolve, reject) => {
                HTTP.get('/order/get-consignees-data')
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setConsigneesData', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getConsigneesCardData({ 
            commit
        }, id) {
            return new Promise((resolve, reject) => {
                HTTP.get('/order/get-consignees-card-data?id=' + id)
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        closeDeliveryPoint({ 
        }, id) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/close-delivery-point', {id})
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        openDeliveryPoint({ 
        }, id) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/open-delivery-point', {id})
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve([])
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        OLDorderCreate({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP_INTRANET.post('/order/create', f)
                    .then(response => {
                        if (response.data)  {
                            resolve(response.data)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        orderCreate({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/create', f)
                    .then(response => {
                        if (response.data && response.data.content)  {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        orderProcess({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP_INTRANET.post('/order/process', f)
                    .then(response => {
                        if (response.data)  {
                            resolve(response.data)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        orderSetdelivery({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP_INTRANET.post('/order/setdelivery', f)
                    .then(response => {
                        if (response.data)  {
                            resolve(response.data)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        orderConfirmation({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP_INTRANET.post('/order/orderConfirmation', f)
                    .then(response => {
                        if (response.data)  {
                            resolve(response.data)
                        } else {
                            resolve()
                        }
                    })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getOrderInfo({
            commit,
        }, orderId) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/order-info', {orderId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setOrderInfo', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                   })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getOrderedProductsInfo({
            commit,
        }, orderId) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-ordered-products', {orderId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setOrderedProducts', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                   })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getPreorderedProductsInfo({
            commit,
        }, orderId) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-preordered-products', {orderId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setPreorderedProducts', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                   })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getNonOrderedProductsInfo({
            commit,
        }, orderId) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-non-ordered-products', {orderId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setNonOrderedProducts', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                   })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getRepairedProductsInfo({
            commit,
        }, orderId) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/get-repaired-products', {orderId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setRepairedProducts', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                   })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        getPriorityWarehouse({
            commit,
        }, regionId) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/priority-warehouse', {regionId})
                    .then(response => {
                        if (response.data && response.data.content) {
                            commit('setPriorityWarehouse', response.data.content)
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                   })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },
        submitConsigneeData({
            commit,
        }, f) {
            return new Promise((resolve, reject) => {
                HTTP.post('/order/submit-consignee-data', f)
                    .then(response => {
                        if (response.data && response.data.content) {
                            resolve(response.data.content)
                        } else {
                            resolve()
                        }
                   })
                    .catch(e => {
                        console.error(e)
                        reject(e)
                    })
            })
        },

    }
}