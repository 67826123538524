<template>
<div>
    <v-chip v-if="item.top5" :outlined="solid ? false : true" :small="solid ? false : true" :class="solid ? `white--text mr-1` : `white--text mr-1 my-1 caption`" color="primary">ТОП</v-chip>
    <v-chip v-if="item.is_new" :outlined="solid ? false : true" :small="solid ? false : true" :class="solid ? `white--text mr-1` : `white--text mr-1 my-1 caption`" color="green">NEW</v-chip>
    <v-chip v-if="item.is_actions" :outlined="solid ? false : true" :small="solid ? false : true" :class="solid ? `white--text mr-1` : `white--text mr-1 my-1 caption`" color="orange">Акція</v-chip>
    <v-chip v-if="item.is_bonuses" :outlined="solid ? false : true" :small="solid ? false : true" :class="solid ? `white--text mr-1` : `white--text mr-1 my-1 caption`" color="blue">Бонус</v-chip>
    <v-chip v-if="item.is_exclusive" :outlined="solid ? false : true" :small="solid ? false : true" :class="solid ? `white--text mr-1` : `white--text mr-1 my-1 caption`" color="purple lighten-1">Ексклюзив</v-chip>
    <v-chip v-if="item.is_priceoff" :outlined="solid ? false : true" :small="solid ? false : true" :class="solid ? `white--text mr-1` : `white--text mr-1 my-1 caption`" color="red">Уцінка {{ item.price_uah_without_action && item.price_uah_without_action > item.priceoff_price_uah ?  '&mdash;'+Math.round(100 * (1-item.priceoff_price_uah / item.price_uah_without_action))+'%' : ''}}</v-chip>
    <v-tooltip right>
        <template v-slot:activator="{ on }">
            <span v-on="on">
                <v-chip v-if="item.is_du" :outlined="solid ? false : true" :small="solid ? false : true" :class="solid ? `white--text mr-1` : `white--text mr-1 my-1 caption`" color="blue lighten-1">ДУ</v-chip>
            </span>
        </template>
        <span>Товар з дефектом пакування. Не був у користуванні.</span>
    </v-tooltip>
</div>
</template>

<script>
export default {
    props: {
        item: Object,
        solid: Boolean,
    },
    data(v) {
        return {}
    }
}
</script>

<style>

</style>
