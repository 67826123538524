import axios from 'axios'

let refreshRequest = null
let isAlreadyFetchingAccessToken = false

const HTTP = axios.create({
    baseURL: (process.env.NODE_ENV === 'production' ? 'https://b2b.yugcontract.ua/api/' : 
    (process.env.NODE_ENV === 'dev_production' ? 'https://dev.b2b.yugcontract.ua/api/' : 'http://dev.yugcontract.ua:3033/api/')
    ),
})

const HTTP_AUTH = axios.create({
    baseURL: (process.env.NODE_ENV === 'production' ? 'https://auth.yugcontract.ua/api/' :
    ( process.env.NODE_ENV === 'dev_production' ? 'https://dev.auth.yugcontract.ua/api/' : 'http://dev.yugcontract.ua:3400/api/')
    ),
})

const HTTP_PRODUCT = axios.create({
    baseURL: (process.env.NODE_ENV === 'production' ? 'https://product.yugcontract.ua/' : 'https://product.yugcontract.ua/'),
})

const HTTP_INTRANET = axios.create({
    baseURL: (process.env.NODE_ENV === 'production' ? 'https://intranet.yugcontract.ua/api/' : 'https://intranet-test3.yugcontract.ua/api/'),
})

// Add a request interceptor
HTTP.interceptors.request.use(config => {
        //fix localstorage 
        try {
            let t = JSON.parse(localStorage.shoppingCartLeft)
        } catch (error) {
            console.log(error)
            localStorage.shoppingCartLeft = JSON.stringify(false)
            console.log('fixed >', localStorage.shoppingCartLeft)
        }

        try {
            let t = JSON.parse(localStorage.compactLine)
        } catch (error) {
            console.log(error)
            localStorage.compactLine = JSON.stringify(false)
            console.log('fixed >', localStorage.compactLine)
        }

        let authToken = ''
        try {
            authToken = localStorage.authToken ? JSON.parse(localStorage.authToken) : ''
        } catch (error) {
            console.log(error)
            localStorage.authToken = JSON.stringify('')
        }

        if (!authToken) {
            return config
        } else {
            const newConfig = {
                headers: {},
                ...config
            }
            newConfig.headers.Authorization = `Bearer ${authToken}`
            return newConfig;
        }

    },
    (error) => Promise.reject(error)
);
HTTP_AUTH.interceptors.request.use(config => {
        let authToken = ''
        try {
            authToken = localStorage.authToken ? JSON.parse(localStorage.authToken) : ''
        } catch (error) {
            console.log(error)
            localStorage.authToken = JSON.stringify('')
        }
        if (!authToken) {
            return config
        } else {
            const newConfig = {
                headers: {},
                ...config
            }
            newConfig.headers.Authorization = `Bearer ${authToken}`
            return newConfig;
        }

    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
HTTP.interceptors.response.use(async response => {
    return response;
}, async error => {
    const originalRequest = error.config
    let authToken = ''
    let refreshToken = ''
    try {
        authToken = localStorage.authToken ? JSON.parse(localStorage.authToken) : ''
    } catch (error) {
        console.log(error)
        localStorage.authToken = JSON.stringify('')
    }
    try {
        refreshToken = localStorage.refreshToken ? JSON.parse(localStorage.refreshToken) : ''
    } catch (error) {
        console.log(error)
        localStorage.refreshToken = JSON.stringify('')
    }
    if (error.response && error.response.status === 401 && !isAlreadyFetchingAccessToken && refreshToken) {
        isAlreadyFetchingAccessToken = true
        if (!refreshRequest) {
            refreshRequest = HTTP_AUTH.post("/auth/refresh", {
                refreshToken,
            })
        }
        //Принимаем новую пару токенов
        const {
            data
        } = await refreshRequest

        if (data.content.token && data.content.refreshToken && !data.content.additional.verificationNeeded) {
            authToken = data.content.token
            refreshToken = data.content.refreshToken
            localStorage['authToken'] = JSON.stringify(authToken)
            localStorage['refreshToken'] = JSON.stringify(refreshToken)
            originalRequest.headers.Authorization = `Bearer ${authToken}`
            refreshRequest = null
            isAlreadyFetchingAccessToken = false
        } 
        return HTTP(originalRequest)
    } else {
        return Promise.reject(error)
    }
})

HTTP_PRODUCT.interceptors.request.use(config => {
        const newConfig = {
            headers: {},
            ...config
        }
        newConfig.headers['server-name'] = process.env.NODE_ENV === 'production' ? `b2b.yugcontract.ua` : `dev.yugcontract.ua`
        return newConfig;
    },
    (error) => Promise.reject(error)
);


export {
    HTTP,
    HTTP_AUTH,
    HTTP_PRODUCT,
    HTTP_INTRANET
}