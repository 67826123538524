var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"app"}},[(
_vm.$route.name !== null &&
_vm.$route.name !== 'login' &&
_vm.$route.name !== 'NewClient' &&
_vm.$route.name !== 'Forgot' &&
_vm.$route.name !== 'Checkout' &&
_vm.$route.name !== 'BalanceDirect' &&
_vm.$route.name !== 'Checkout-success' &&
_vm.$route.name !== 'Checkout-preorder-success' &&
_vm.$route.name !== 'CheckoutPreorder' &&
_vm.$route.name !== 'empty' &&
_vm.$route.name !== 'emptyClient' &&
_vm.showToolbar
)?_c('Toolbar'):_vm._e(),_c('v-progress-linear',{staticClass:"linear ma-0",attrs:{"fixed":"","active":_vm.$store.state.main.loading,"color":"error","indeterminate":true,"height":"3"}}),_c('v-main',{style:(_vm.$route.name !== 'home' && _vm.$vuetify.breakpoint.lgAndUp ? `padding-left: 55px;` : ``)},[_c('router-view')],1),_c('v-snackbar',{attrs:{"color":"transparent","elevation":"0","timeout":_vm.snackbar().timeout || 4000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({staticClass:"ml-n2",attrs:{"text":"","icon":""},on:{"click":function($event){_vm.snackbar().model = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"color":"second"}},[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar().model),callback:function ($$v) {_vm.$set(_vm.snackbar(), "model", $$v)},expression:"snackbar().model"}},[_c('v-alert',{staticClass:"body-2 ma-0 mb-1",attrs:{"color":_vm.snackbar().color,"icon":_vm.snackbar().icon || 'mdi-alert'}},[_vm._v(" "+_vm._s(_vm.snackbar().text)+" ")]),(_vm.snackbar().alert)?_c('v-alert',{staticClass:"body-2",attrs:{"type":"warning","icon":"mdi-alert","color":"orange"}},[_vm._v(" "+_vm._s(_vm.snackbar().alert)+" ")]):_vm._e()],1),_c('v-fab-transition',[(this.$vuetify.breakpoint.smAndDown && _vm.scrolled && _vm.$route.name !== 'Product')?_c('v-btn',{attrs:{"color":"primary","dark":"","fixed":"","bottom":"","right":"","fab":""},on:{"click":function($event){return _vm.$vuetify.goTo(0)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e(),(this.$vuetify.breakpoint.smAndUp && _vm.scrolled )?_c('v-btn',{attrs:{"color":"primary","dark":"","fixed":"","bottom":"","right":"","fab":""},on:{"click":function($event){return _vm.$vuetify.goTo(0)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('v-footer',[_c('v-spacer'),_c('div',[_vm._v("Юг-Контракт © "+_vm._s(new Date().getFullYear()))]),_c('v-spacer')],1),_c('OnbehalfDialog'),_c('EmptyClientDialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }